import React, {
    DetailedHTMLProps,
    HTMLAttributes,
    MouseEvent,
    createRef,
    useEffect,
    useState, useLayoutEffect,
} from "react";

import clsx from "clsx";
import { navigate } from "gatsby";
import { useDispatch, useSelector } from "react-redux";
import StoreState from "@js/store/StoreState";
import useDebounce from "@js/hook/useDebounce";

import {
    setArea,
    setAreaCode,
    setAreaManual,
    setAreaCodeBag
} from "@js/actionCreators/informationActionCreators";
import { setBuildingType } from "@js/actionCreators/informationActionCreators";
import { setZipCode } from "@js/actionCreators/informationActionCreators";
import { setHouseNumber } from "@js/actionCreators/informationActionCreators";
import { setHouseNumberAddition } from "@js/actionCreators/informationActionCreators";

import Heading from "@js/component/Heading";
import InformationForm from "@js/component/InformationForm";
import {setDeliveryTime, setMethodology, setReviewAverage} from "@js/actionCreators/filterActionCreators";

import * as classNames from "@css/component/AdvisorSearch.module.scss";

/**
 * @type AdvisorSearchProps
 */
export type AdvisorSearchProps = Omit<DetailedHTMLProps<HTMLAttributes<HTMLElement>, HTMLElement>, "children">;

/**
 * @const AdvisorSearch
 */
const AdvisorSearch = (props: AdvisorSearchProps) => {
    const {
        className,
        ...restProps
    } = props;

    const dispatch = useDispatch();

    const debounce = useDebounce();

    const formRef = createRef<HTMLFormElement>();
    const [tempBuildingType, setTempBuildingType] = useState("");
    const [tempZipCode, setTempZipCode] = useState("");
    const [tempHouseNumber, setTempHouseNumber] = useState("");
    const [tempHouseNumberAddition, setTempHouseNumberAddition] = useState("");
    const [showNotificationZipcode, setShowNotificationZipcode] = useState(false);
    const [showNotificationHouseNumber, setShowNotificationHouseNumber] = useState(false);
    const [isInvalidBag, setIsInvalidBag] = useState(false);
    const [isFetchingArea, setIsFetchingArea] = useState(false);
    const [isButtonDisabled, setIsButtonDisabled] = useState(false);
    const [invalidBuildingType, setInvalidBuildingType] = useState(false);

    const productChoice = useSelector((storeState: StoreState) => storeState.information.productChoice);
    const buildingTypeGroup = useSelector((storeState: StoreState) => storeState.information.buildingTypeGroup);
    const buildingType = useSelector((storeState: StoreState) => storeState.information.buildingType);
    const zipCode = useSelector((storeState: StoreState) => storeState.information.zipCode);
    const houseNumber = useSelector((storeState: StoreState) => storeState.information.houseNumber);
    const houseNumberAddition = useSelector((storeState: StoreState) => storeState.information.houseNumberAddition);
    const areaManual = useSelector((storeState: StoreState) => storeState.information.areaManual);

    const onUpdateButtonClick = (event: MouseEvent) => {
        event.preventDefault();

        if(!tempHouseNumber) {
            setShowNotificationHouseNumber(true);
        }

        if(!tempZipCode) {
            setShowNotificationZipcode(true);
        }

        //if( (isInvalidBag === false || buildingTypeGroup == 'business')) {
            if (formRef.current?.reportValidity()) {
                dispatch(setZipCode(tempZipCode));
                dispatch(setHouseNumber(tempHouseNumber));

                if (tempHouseNumberAddition == "") {
                    dispatch(setHouseNumberAddition(""));
                } else {
                    dispatch(setHouseNumberAddition(tempHouseNumberAddition));
                }

                if (!buildingType) {
                    setInvalidBuildingType(true);
                } else {
                    setInvalidBuildingType(false);
                    navigate("/vergelijk");
                }
            }
        //}
    };

    const fetchArea = async () => {
        if (tempZipCode && tempHouseNumber) {
            setIsFetchingArea(true);

            const response = await fetch(`${ process.env.GATSBY_API_PUBLIC_URL }/bag/${ buildingTypeGroup }/${ tempZipCode }/${ tempHouseNumber }/${ tempHouseNumberAddition }`);
            debounce(() => {
                setIsFetchingArea(false);
            });

            const data = await response.json();

            if (!(data.status == "success")) {
                setIsInvalidBag(true);

                if (!areaManual) {
                    dispatch(setArea(''));
                    dispatch(setAreaCode(''));
                    dispatch(setAreaCodeBag(''));
                }
                return;
            }

            setIsInvalidBag(false);
            if (buildingTypeGroup === 'private' || (buildingTypeGroup === 'business' && !areaManual)) {
                dispatch(setArea(data.result.area));
                dispatch(setAreaCode(data.result.areaCode));
                dispatch(setAreaCodeBag(data.result.areaCode));
                // dispatch(setAreaManual(false));
            }
        }
    };

    useEffect(() => {
        setTempBuildingType(buildingType);
        setTempZipCode(zipCode);
        setTempHouseNumber(houseNumber);
        setTempHouseNumberAddition(houseNumberAddition);
    }, [buildingTypeGroup]);

    useEffect(() => {
        if(!tempZipCode || !tempHouseNumber || !tempBuildingType || isInvalidBag && !areaManual) {
            setIsButtonDisabled(true);
        } else {
            setIsButtonDisabled(false);
        }
    }, [tempZipCode, tempHouseNumber, tempBuildingType, isInvalidBag, areaManual]);

    useLayoutEffect(() => {
        dispatch(setReviewAverage(""));
        dispatch(setDeliveryTime(""));
        dispatch(setMethodology("basic"));

        debounce(() => {
            void fetchArea();

            if(!tempZipCode || !tempHouseNumber) {
                dispatch(setAreaManual(false));
            }
        });

    }, [tempZipCode, tempHouseNumber, tempHouseNumberAddition, buildingTypeGroup]);

    return (
        <section { ...restProps } className={ clsx(classNames.advisorSearch, className) }>
            <Heading className={ classNames.heading } element="h1">
                Start hier <strong>jouw vergelijking</strong>!
            </Heading>
            <InformationForm
                buildingTypeValue={ tempBuildingType }
                buildingTypeCategory={ buildingTypeGroup }
                isButtonDisabled={ isFetchingArea || (isInvalidBag && !areaManual) || !productChoice }
                buttonClassName={ clsx({ [classNames.buttonDisabled]: isButtonDisabled }) }
                formRef={ formRef }
                onBuildingTypeChange={ (event) => {
                    setTempBuildingType(event.currentTarget.value);
                    dispatch(setBuildingType(event.currentTarget.value));
                }}
                onButtonClick={ onUpdateButtonClick }
                onZipCodeChange={ (event) => {
                    setTempZipCode(event.currentTarget.value.toUpperCase());
                    debounce( () => {
                        void fetchArea();
                    });
                }}
                onHouseNumberChange={ (event) => {
                    setTempHouseNumber(event.currentTarget.value);
                    debounce( () => {
                        void fetchArea();
                    });
                }}
                onHouseNumberAdditionChange={ (event) => {
                    setTempHouseNumberAddition(event.currentTarget.value.toUpperCase());
                    debounce( () => {
                        void fetchArea();
                    });
                }}
                showNotificationHouseNumber={ showNotificationHouseNumber }
                showNotificationZipcode={ showNotificationZipcode }
                zipCodeValue={ tempZipCode }
                houseNumberValue={ tempHouseNumber }
                houseNumberAdditionValue={ tempHouseNumberAddition }
                isInvalidBag={ isInvalidBag }
                isInvalidBuildingType={ invalidBuildingType }
            />
        </section>
    );
};

export default AdvisorSearch;
